/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle'
import { faLock } from '@fortawesome/pro-regular-svg-icons/faLock'
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash'
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle'
import { faLink } from '@fortawesome/pro-regular-svg-icons/faLink'
import { faCloudDownloadAlt } from '@fortawesome/pro-regular-svg-icons/faCloudDownloadAlt'
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons/faQuestionCircle'
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes'
import { faEdit } from '@fortawesome/pro-regular-svg-icons/faEdit'
import { string, object, func, array } from 'prop-types'
import { Redirect } from 'react-router-dom'
import AddressForm from './AddressForm'
import { toast } from 'react-toastify'
import { Field } from 'formik'
import { Address as SAddress } from './styled'
import * as yup from 'yup'
import get from 'lodash/get'

import {
    getSchemaFromResource,
    useClient,
    CREATE,
    UPDATE,
    GET_LIST,
    GET_ONE,
    DELETE,
} from '@peracto/client'
import {
    FormActions,
    Textarea,
    Select,
    Modal,
    Group,
    Input,
    Form,
    DateInput,
    Tags,
} from '@peracto/peracto-ui'
import { useConfig } from '@peracto/peracto-config'
import { userRoles } from '../utils'

import { StyledButton } from '../../../theme'
import fileDownload from 'js-file-download'
export const MODE_ADD = 'add'
export const MODE_EDIT = 'edit'

const UserForm = ({
    setFormData = () => {},
    mode = MODE_EDIT,
    onSaveAddress,
    values,
    fetchUser = () => {},
    ...props
}) => {
    const editedValues =
        mode === MODE_EDIT
            ? {
                  ...values,
                  user: {
                      ...values.user,

                      preferredCollectionLocation: get(
                          values.user.preferredCollectionLocation,
                          '@id'
                      ),
                  },
              }
            : values

    const [showUserDialog, setShowUserDialog] = useState(false)
    const config = useConfig()

    const [customerGroups, setCustomerGroups] = useState()
    const [visibleFields, setVisibleFields] = useState({})
    const [redirect, setRedirect] = useState()
    const [showModal, setShowModal] = useState(false)
    const [modalData, setModalData] = useState({})
    const [userGroups, setUserGroups] = useState([])
    const { user } = config.get('features', {})

    const [showPasswordReset, setShowPasswordReset] = useState(false)
    const [sendingPasswordReset, setSendingPasswordReset] = useState(false)

    const { client, getResource } = useClient()

    const schema = yup.object().shape({
        user: getSchemaFromResource(getResource('users')).shape({
            creditLimitRequest: yup.number().nullable(),
        }),
    })

    const titles = [
        { label: 'Mr', value: 'mr' },
        { label: 'Mrs', value: 'mrs' },
        { label: 'Miss', value: 'miss' },
        { label: 'Ms', value: 'ms' },
        { label: 'Mx', value: 'mx' },
        { label: 'Prof.', value: 'prof' },
        { label: 'Dr', value: 'dr' },
        { label: 'Rev', value: 'rev' },
        { label: 'Lord', value: 'lord' },
        { label: 'Lady', value: 'lady' },
        { label: 'Sir', value: 'sir' },
        { label: 'Dame', value: 'dame' },
        { label: 'Baron', value: 'baron' },
    ]

    const onDelete = async () => {
        try {
            await client(DELETE, 'users', {
                id: editedValues.user.id,
            })

            toast.success('User deleted successfully!')
            setRedirect('/users')
        } catch (e) {
            console.error(e)
            toast.error('Whoops, there was a problem...')
        }
    }

    const onDownloadCashApplicationPDF = async () => {
        try {
            const url = config.get('api') + `${values.user.id}/download-cash-application`
            const token = localStorage.getItem('token')

            fetch(url, {
                headers: { authorization: `bearer ${token}` },
                responseType: 'blob',
            })
                .then((res) => res.blob())
                .then((blob) => fileDownload(blob, 'application.pdf'))
        } catch (e) {
            console.error(e)
            toast.error('Whoops, there was a problem...')
        }
    }

    const onDownloadCreditApplicationPDF = async () => {
        try {
            const url = config.get('api') + `${values.user.id}/download-credit-application`
            const token = localStorage.getItem('token')

            fetch(url, {
                headers: { authorization: `bearer ${token}` },
                responseType: 'blob',
            })
                .then((res) => res.blob())
                .then((blob) => fileDownload(blob, 'application.pdf'))
        } catch (e) {
            console.error(e)
            toast.error('Whoops, there was a problem...')
        }
    }

    const onSaveResidentialAddress = async (residentialAddress, values) => {
        try {
            await client(UPDATE, 'users', {
                id: `${values.user.id}`,
                data: { residentialAddress },
            })

            toast.success('Residential Address edited successfully!')

            const newFormData = {
                ...values,
                ...{ user: { ...values.user, residentialAddress } },
            }

            setFormData(newFormData)
        } catch (e) {
            console.error(e)
            toast.error('Whoops, there was a problem...')
        }
    }

    const onDownloadDocumentClick = async (uploadPath) => {
        try {
            const { data } = await client(GET_ONE, uploadPath, {
                id: uploadPath,
            })

            // Open file in new window, because downloading is blocked by CORS.
            window.open(data.downloadUrl, '_blank')
        } catch (err) {
            console.warn(err)
        }
    }

    const onResetPassword = async () => {
        setSendingPasswordReset(true)
        try {
            await client(CREATE, 'users/reset-password', {
                data: {
                    email: editedValues.user.email,
                },
            })

            setShowPasswordReset(false)
            setSendingPasswordReset(false)
            toast.success('Password reset email sent!')
        } catch (e) {
            console.error(e)
            setSendingPasswordReset(false)
            toast.error('Whoops, there was a problem...')
        }
    }

    const fetchCustomerGroups = useCallback(
        async (inputValue = '') => {
            const { data } = await client(GET_LIST, 'customer-groups', {
                id: 'customer-groups',
                label: inputValue,
            })

            const values = data.map((val) => ({
                label: val.name,
                value: val.id,
            }))

            setCustomerGroups(values)

            return values
        },
        [client]
    )

    const fetchUserGroups = async (inputValue = '') => {
        const { data } = await client(GET_LIST, 'user-groups', {
            id: 'user-groups',
            label: inputValue,
        })

        const values = data.map((val) => ({
            label: val.name,
            value: val.identifier,
        }))

        setUserGroups(values)

        return values
    }

    useEffect(() => {
        if (user?.userGroups) {
            fetchUserGroups()
        } else {
            fetchCustomerGroups()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    const sharedPutOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({}),
    }

    const ApprovalModal = ({ form }) => {
        return (
            <Modal
                isVisible={showModal}
                title="Unsaved Changes"
                close={() => setShowModal(false)}
                buttons={[
                    {
                        type: 'btn-outline-secondary',
                        text: 'Cancel',
                        action: () => {
                            setShowModal(false)
                        },
                    },
                    {
                        type: 'btn-success',
                        text: 'Save',
                        action: async () => {
                            const { values, ...actions } = form

                            await props.onSubmit(values, actions)
                            if (typeof modalData?.action === 'function') {
                                await modalData.action()
                            }

                            if (!form.isValidating && !form.isSubmitting) {
                                setShowModal(false)
                            }
                        },
                    },
                ]}
            >
                <FontAwesomeIcon icon={faQuestionCircle} size="4x" className="d-block mb-4" />
                Would you like to save the current changes to the form before continuing?
            </Modal>
        )
    }

    const putCashApplication = async (endpoint, action, loadUser) => {
        try {
            const url = config.get('api') + `${editedValues.user.id}/${endpoint}-cash-application`
            await fetch(url, sharedPutOptions)

            if (loadUser) {
                fetchUser()
            }

            toast.success(`Cash Application ${action}`)
        } catch (e) {
            console.error(e)
            toast.error('Whoops, there was a problem...')
        }
    }

    const putCreditApplication = async (endpoint, action, loadUser) => {
        try {
            const url = config.get('api') + `${editedValues.user.id}/${endpoint}-credit-application`
            await fetch(url, sharedPutOptions)

            if (loadUser) {
                fetchUser()
            }

            toast.success(`Credit Application ${action}`)
        } catch (e) {
            console.error(e)
            toast.error('Whoops, there was a problem...')
        }
    }

    const approveCashApplication = async (loadUser = false) => {
        await putCashApplication('approve', 'Approved', loadUser)
    }

    const rejectCashApplication = async (loadUser = false) => {
        await putCashApplication('reject', 'Rejected', loadUser)
    }

    const approveCreditApplication = async (loadUser = false) => {
        await putCreditApplication('approve', 'Approved', loadUser)
    }

    const rejectCreditApplication = async (loadUser = false) => {
        await putCreditApplication('reject', 'Rejected', loadUser)
    }

    return (
        <>
            {redirect && <Redirect to={redirect} />}

            {mode === MODE_EDIT && (
                <FormActions>
                    <>
                        <StyledButton
                            style={{ marginRight: '10px' }}
                            onClick={() => setShowPasswordReset(true)}
                        >
                            <FontAwesomeIcon icon={faLock} className="mr-2" />
                            Reset Password
                        </StyledButton>

                        <StyledButton
                            className="text-danger"
                            onClick={() => setShowUserDialog(true)}
                        >
                            <FontAwesomeIcon icon={faTrash} className="mr-2" />
                            Delete User
                        </StyledButton>
                    </>
                </FormActions>
            )}

            <Form autoComplete="off" values={editedValues} {...props} schema={schema}>
                <Group key="customer" id="customer" name="Customer">
                    {mode === MODE_EDIT && (
                        <div className="d-flex justify-content-between align-items-start">
                            <div>
                                {!visibleFields.customer && (
                                    <SAddress>
                                        <p className="address-name">
                                            {editedValues.user.firstName}{' '}
                                            {editedValues.user.lastName}
                                        </p>
                                        <p>{editedValues.user.email}</p>
                                        <p>{editedValues.user.telephone}</p>
                                        <p>
                                            {editedValues.user.roles.map((val, idx) => {
                                                const found = userRoles.find(
                                                    (role) => role.value === val
                                                )
                                                return found ? (
                                                    <span key={`role-${idx}`}>
                                                        {idx > 0 ? ', ' : ''}
                                                        {found.label}
                                                    </span>
                                                ) : (
                                                    ''
                                                )
                                            })}
                                        </p>
                                    </SAddress>
                                )}
                            </div>

                            <button
                                type="button"
                                className="btn btn-link p-0"
                                onClick={() => {
                                    setVisibleFields({
                                        ...visibleFields,
                                        customer: !visibleFields.customer,
                                    })
                                }}
                            >
                                {visibleFields.customer ? (
                                    <>
                                        <FontAwesomeIcon icon={faTimes} className="mr-2" />
                                        Close
                                    </>
                                ) : (
                                    <>
                                        <FontAwesomeIcon icon={faEdit} className="mr-2" />
                                        Edit
                                    </>
                                )}
                            </button>
                        </div>
                    )}

                    {(mode === MODE_ADD || visibleFields.customer) && (
                        <>
                            <Select
                                placeholder="Select a title"
                                name="user.title"
                                options={titles}
                                label="Title"
                            />
                            <Input
                                autoComplete="first-name"
                                name="user.firstName"
                                label="First name"
                            />
                            <Input
                                autoComplete="last-name"
                                name="user.lastName"
                                label="Last name"
                            />
                            <Input
                                name="user.email"
                                label="Email"
                                autoComplete="email"
                                disabled={mode === MODE_EDIT}
                            />
                            <Input
                                name="user.telephone"
                                label="Telephone"
                                disabled={mode === MODE_EDIT}
                            />
                            <Tags name="user.roles" label="Roles" options={userRoles} />

                            {user?.userGroups ? (
                                <>
                                    {userGroups?.length > 0 && (
                                        <Field name="user.userGroup">
                                            {({ field, form }) => (
                                                <div className="form-group">
                                                    <label>User Group</label>
                                                    <Select
                                                        name={field.name}
                                                        className="w-100"
                                                        isSearchable={true}
                                                        onChange={(option) => {
                                                            form.setFieldValue(
                                                                field.name,
                                                                option.value
                                                            )
                                                        }}
                                                        defaultValue={{
                                                            label: 'Search for User Groups...',
                                                            value: null,
                                                        }}
                                                        options={userGroups}
                                                        placeholder="Search for User Groups..."
                                                        testId="user-groups"
                                                        testIdItems="user-groups__item"
                                                        testIdIndex={1}
                                                        allowClear={true}
                                                        disabled={true}
                                                    />
                                                </div>
                                            )}
                                        </Field>
                                    )}
                                </>
                            ) : (
                                <Field name="user.customerGroup">
                                    {({ field, form }) => (
                                        <div className="form-group">
                                            <label>Customer Group</label>
                                            {customerGroups ? (
                                                <Select
                                                    disabled
                                                    name={field.name}
                                                    className="w-100"
                                                    isSearchable={true}
                                                    onChange={(option) => {
                                                        form.setFieldValue(field.name, option.value)
                                                    }}
                                                    options={customerGroups}
                                                    placeholder="Search for Customer Groups..."
                                                />
                                            ) : (
                                                <p className="help-text">Loading...</p>
                                            )}
                                        </div>
                                    )}
                                </Field>
                            )}
                            <Select
                                name="user.currency"
                                label="Currency"
                                options={[{ label: 'GBP', value: 'GBP' }]}
                                placeholder="Select a currency"
                                testId="currency"
                                testIdItems="currency__item"
                                testIdIndex={0}
                            />
                            <Select
                                label="Communication preferences"
                                name="user.communicationPreference"
                                options={[
                                    {
                                        label: 'Email',
                                        value: 'email',
                                    },
                                    {
                                        label: 'SMS',
                                        value: 'sms',
                                    },
                                    {
                                        label: 'Both',
                                        value: 'both',
                                    },
                                ]}
                            />
                            <Select
                                label="GSR Status"
                                name="user.gsrStatus"
                                options={[
                                    {
                                        label: 'Active',
                                        value: 'active',
                                    },
                                    {
                                        label: 'Inactive',
                                        value: 'inactive',
                                    },
                                ]}
                            />

                            {mode === MODE_EDIT && (
                                <div className="mb-3">
                                    <Select
                                        label="User can buy age-restricted products"
                                        name="user.canBuyAgeRestrictedProducts"
                                        options={[
                                            { label: 'Yes', value: true },
                                            { label: 'No', value: false },
                                        ]}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </Group>

                {(mode === MODE_ADD || visibleFields.customer) && (
                    <>
                        <Group
                            name="Kerridge Data"
                            id="kerridge-data"
                            children={
                                <Input name="user.k8AccountNumber" label="K8 Account Number" />
                            }
                        />
                        <Group id="cash-account-information" name="Cash Application Information">
                            {editedValues?.user && (
                                <h6>
                                    {editedValues.user.cashApplicationStatus === 'pending' && (
                                        <span>
                                            Cash Application status:{' '}
                                            <span className="badge badge-info">Pending</span>
                                        </span>
                                    )}
                                    {editedValues.user.cashApplicationStatus === 'approved' && (
                                        <span>
                                            Cash Application status:{' '}
                                            <span className="badge badge-success">Active</span>
                                        </span>
                                    )}
                                    {editedValues.user.cashApplicationStatus === 'rejected' && (
                                        <span>
                                            Cash Application status:{' '}
                                            <span className="badge badge-danger">Rejected</span>
                                        </span>
                                    )}
                                </h6>
                            )}
                            <Input
                                name="user.businessName"
                                label="Business name"
                                disabled={mode === MODE_EDIT}
                            />
                            <Input name="user.telephone" label="Telephone" />
                            <Input name="user.mobile" label="Mobile" />
                            <Input
                                name="user.gsrRegistrationNumber"
                                label="GSR registration number"
                            />
                            <Input name="user.gsrExpiryDate" label="GSR expiry date" type="date" />
                            <Input name="user.tradeStatus" label="Proof of trade status" />
                            <Textarea
                                name="user.cashApplicationSupportingInformation"
                                label="Supporting information"
                            />

                            {editedValues?.user?.proofOfTradeStatusSecureFileUploads?.length >
                                0 && (
                                <>
                                    <div>Proof of Trade Documents</div>
                                    <div className="row mb-4">
                                        <div className="col">
                                            <ul className="list-group">
                                                <Field name="user.proofOfTradeStatusSecureFileUploads">
                                                    {({ field, form }) => {
                                                        if (!field.value) return null
                                                        return (
                                                            <>
                                                                {field.value.map((uploadPath) => (
                                                                    <li
                                                                        className="list-group-item d-flex justify-content-between align-items-center"
                                                                        key={uploadPath}
                                                                        onClick={() =>
                                                                            onDownloadDocumentClick(
                                                                                uploadPath
                                                                            )
                                                                        }
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    >
                                                                        <span>{uploadPath}</span>

                                                                        <FontAwesomeIcon
                                                                            icon={faLink}
                                                                        />
                                                                    </li>
                                                                ))}
                                                            </>
                                                        )
                                                    }}
                                                </Field>
                                            </ul>
                                        </div>
                                    </div>
                                </>
                            )}

                            <div className="d-flex justify-content-between align-items-center">
                                <StyledButton
                                    className="btn btn-link px-0"
                                    onClick={onDownloadCashApplicationPDF}
                                    type="button"
                                >
                                    <FontAwesomeIcon icon={faCloudDownloadAlt} className="mr-2" />
                                    Download Application
                                </StyledButton>

                                <Field>
                                    {({ form }) => (
                                        <>
                                            {editedValues?.user?.cashApplicationStatus ===
                                                'pending' && (
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-success mr-2"
                                                        onClick={() => {
                                                            if (form.dirty) {
                                                                setModalData({
                                                                    action: approveCashApplication,
                                                                })
                                                                setShowModal(true)
                                                            } else {
                                                                approveCashApplication(true)
                                                            }
                                                        }}
                                                    >
                                                        Approve Cash Application
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger"
                                                        onClick={() => {
                                                            if (form.dirty) {
                                                                setModalData({
                                                                    action: rejectCashApplication,
                                                                })
                                                                setShowModal(true)
                                                            } else {
                                                                rejectCashApplication(true)
                                                            }
                                                        }}
                                                    >
                                                        Reject Cash Application
                                                    </button>

                                                    <ApprovalModal form={form} />
                                                </div>
                                            )}
                                        </>
                                    )}
                                </Field>
                            </div>
                        </Group>

                        <Group name="Credit Application Information" key="customer" id="customer">
                            {editedValues?.user && (
                                <h6>
                                    {editedValues.user.creditApplicationStatus === 'pending' && (
                                        <span>
                                            Credit Application status:{' '}
                                            <span className="badge badge-info">Pending</span>
                                        </span>
                                    )}
                                    {editedValues.user.creditApplicationStatus === 'approved' && (
                                        <span>
                                            Credit Application status:{' '}
                                            <span className="badge badge-success">Approved</span>
                                        </span>
                                    )}
                                    {editedValues.user.creditApplicationStatus === 'rejected' && (
                                        <span>
                                            Credit Application status:{' '}
                                            <span className="badge badge-danger">Rejected</span>
                                        </span>
                                    )}
                                </h6>
                            )}
                            <Select
                                name="user.businessEntityType"
                                label="Type of organisation"
                                defaultValue="test"
                                options={[
                                    {
                                        label: 'Sole Trader',
                                        value: 'sole-trader',
                                    },
                                    {
                                        label: 'Partnership',
                                        value: 'partnership',
                                    },
                                    {
                                        label: 'LTD company',
                                        value: 'ltd-company',
                                    },
                                    {
                                        label: 'LLP',
                                        value: 'llp',
                                    },
                                ]}
                            />
                            <Input label="VAT Reg. Number" name="user.vatNumber" />
                            <Input label="Company Reg. Number" name="user.companyNumber" />
                            <Input
                                label="Credit Limit Request"
                                name="user.creditLimitRequest"
                                type="number"
                            />
                            <Input
                                label="Number of years at residential address"
                                name="user.yearsAtResidentialAddress"
                                type="number"
                            />

                            <DateInput
                                name="user.dateOfBirth"
                                label="Date of birth"
                                className="flex-grow-1"
                                rawDate={
                                    mode === MODE_ADD || editedValues?.user?.dateOfBirth === null
                                }
                            />

                            <div className="d-flex">
                                <div className="flex-grow-1 mr-2">
                                    <Input
                                        name="user.drivingLicenceNumber"
                                        label="Driving licence number"
                                    />
                                </div>
                                <div className="flex-grow-1">
                                    <DateInput
                                        name="user.drivingLicenceIssueDate"
                                        label="Driving licence issue date"
                                        className="flex-grow-1"
                                        rawDate={
                                            mode === MODE_ADD ||
                                            editedValues?.user.drivingLicenceIssueDate === null
                                        }
                                    />
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="flex-grow-1 mr-2">
                                    <Input name="user.passportNumber" label="Passport number" />
                                </div>
                                <div className="flex-grow-1">
                                    <DateInput
                                        name="user.passportExpiryDate"
                                        label="Passport expiry date"
                                        className="flex-grow-1"
                                        rawDate={
                                            mode === MODE_ADD ||
                                            editedValues?.user.passportExpiryDate === null
                                        }
                                    />
                                </div>
                            </div>
                            {editedValues?.user?.proofOfIdSecureFileUpload && (
                                <>
                                    <div>Proof of ID Document</div>
                                    <div className="row mb-4">
                                        <div className="col">
                                            <ul className="list-group">
                                                <Field name="user.proofOfIdSecureFileUpload">
                                                    {({ field }) => {
                                                        if (!field.value) return null
                                                        return (
                                                            <li
                                                                className="list-group-item d-flex justify-content-between align-items-center"
                                                                key={field.value}
                                                                onClick={() =>
                                                                    onDownloadDocumentClick(
                                                                        field.value
                                                                    )
                                                                }
                                                                style={{
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                <span>{field.value}</span>

                                                                <FontAwesomeIcon icon={faLink} />
                                                            </li>
                                                        )
                                                    }}
                                                </Field>
                                            </ul>
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className="d-flex justify-content-between align-items-start">
                                <a
                                    className="btn btn-link px-0"
                                    href="#"
                                    onClick={onDownloadCreditApplicationPDF}
                                >
                                    <FontAwesomeIcon icon={faCloudDownloadAlt} className="mr-2" />
                                    Download Application
                                </a>

                                {editedValues?.user?.creditApplicationStatus === 'pending' && (
                                    <div className="d-flex flex-column align-items-end justify-content-end">
                                        <Field>
                                            {({ form }) => (
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-success mr-2"
                                                        onClick={() => {
                                                            if (form.dirty) {
                                                                setShowModal(true)

                                                                setModalData({
                                                                    action: approveCreditApplication,
                                                                })
                                                            } else {
                                                                approveCreditApplication(true)
                                                            }
                                                        }}
                                                        disabled={
                                                            editedValues.user
                                                                .cashApplicationStatus === 'pending'
                                                        }
                                                    >
                                                        Approve Credit Application
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger"
                                                        onClick={() => {
                                                            if (form.dirty) {
                                                                setShowModal(true)

                                                                setModalData({
                                                                    action: rejectCreditApplication,
                                                                })
                                                            } else {
                                                                rejectCreditApplication(true)
                                                            }
                                                        }}
                                                        disabled={
                                                            editedValues.user
                                                                .cashApplicationStatus === 'pending'
                                                        }
                                                    >
                                                        Reject Credit Application
                                                    </button>

                                                    <ApprovalModal form={form} />
                                                </div>
                                            )}
                                        </Field>

                                        {editedValues.user.cashApplicationStatus === 'pending' && (
                                            <p className="mt-2">
                                                <FontAwesomeIcon icon={faInfoCircle} /> You must
                                                approve the pending Cash application before
                                                approving the user's Credit application.
                                            </p>
                                        )}
                                    </div>
                                )}
                            </div>
                        </Group>
                    </>
                )}

                {/* The password fields need be part of the same form when adding a new user */}
                {mode === MODE_ADD && (
                    <Group key="password" id="password" name="Password">
                        <Input
                            autoComplete="new-password"
                            name="user.password"
                            label="New Password"
                            type="password"
                        />
                        <Input
                            name="user.password_repeat"
                            label="Repeat password"
                            type="password"
                        />
                    </Group>
                )}
            </Form>

            {mode === MODE_EDIT && (
                <>
                    <AddressForm
                        values={editedValues}
                        visibleFields={visibleFields}
                        setVisibleFields={setVisibleFields}
                        setFormData={setFormData}
                        titles={titles}
                        onSaveAddress={onSaveAddress}
                        onSaveResidentialAddress={onSaveResidentialAddress}
                        {...props}
                    />

                    <Modal
                        isVisible={showUserDialog}
                        title="Delete User"
                        close={() => setShowUserDialog(false)}
                        buttons={[
                            {
                                type: 'btn-outline-secondary',
                                text: 'Close',
                                action: () => setShowUserDialog(false),
                            },
                            {
                                type: 'btn-danger',
                                text: 'Delete User',
                                action: () => onDelete(),
                            },
                        ]}
                    >
                        <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            size="4x"
                            className="d-block mb-4"
                        />
                        Are you sure you would like to permanently delete the account of{' '}
                        {editedValues.user.email}? Deleted users cannot be recovered.
                    </Modal>

                    <Modal
                        isVisible={showPasswordReset}
                        title="Reset Password"
                        close={() => setShowPasswordReset(false)}
                        buttons={[
                            {
                                type: 'btn-outline-secondary',
                                text: 'Cancel',
                                action: () => setShowPasswordReset(false),
                            },
                            {
                                type: 'btn-success',
                                text: sendingPasswordReset ? 'Sending Email...' : 'Send Email',
                                disabled: sendingPasswordReset,
                                action: () => onResetPassword(),
                            },
                        ]}
                    >
                        <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            size="4x"
                            className="d-block mb-4"
                        />
                        Are you sure you would like to generate a reset password email for{' '}
                        {editedValues.user.email}?
                    </Modal>
                </>
            )}
        </>
    )
}

UserForm.displayName = 'UserForm'
UserForm.propTypes = {
    onSubmit: func.isRequired,
    setFormData: func,
    countries: array,
    values: object,
    mode: string,
}

export default UserForm
